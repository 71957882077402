import React from "react"

import Img from "../common/Img"
import s from "./ProductGroupCard.module.scss"

export default function ProductGroupCard({ name, imageUrl }) {
  return (
    <figure className={s.productCard}>
      <div className={s.imageHolder}>
        {imageUrl ? (
          <Img alt={name} className={s.image} src={imageUrl} />
        ) : null}
      </div>
      <figcaption className={s.caption}>{name}</figcaption>
    </figure>
  )
}
